body {
  background-color: #f3f0f0;
  overflow-x: hidden;
  --main-color: #1d314c;
  --starCount: 25;
}

#intro {
  color: #959595FF;
  font-family: 'Fira Code', monospace;
  font-size: 1em;
  top: 0;
  height: 100vh;
  width: 100vw;
  text-align: center;
}

#intro * {
  z-index: 999;
}

canvas {
  background: #232323;
  position:absolute;
  top:0;
  left:0;
  z-index:0;
  transition: opacity 1s;
}
.flex-parent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: inherit;
}

#typewriter {
  font-size: 2em;
}

.caret {
  border-right: .05em solid;
  animation: caret 1s steps(1) infinite;
}

@keyframes caret {
  50% {
    border-color: transparent;
  }
}

#enter {
  font-size: 1.25em;
  transition: opacity 5s;
  margin-top: 2%;
  margin-left: 25%;
}
#enter a {
  color: inherit;
  text-decoration: none;
}

.invisible {
  opacity: 0;
}

.hidden {
  display: none !important;
}

#nav {
  color: var(--main-color);
  display: flex;
  position: absolute;
  top: 0;
  font-family: 'Playfair Display', serif;
  font-size: 1.3em;
  line-height: 1.5em;
  margin: 2em;
}

.name-logo {
  border: 0.2em solid var(--main-color);
  padding: .25em;
  font-weight: bold;
  height: max-content;
  margin-right: 2em;
}
.name-full {
  display: none;
}

.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 2ch;
  margin-bottom: 2ch;
}
.row > * {
  flex-basis: 100%;
}

.box {
  border: 0.25em solid #1d314c;
  padding: 0.5em;
}

.box > h3 {
  margin-top: 0;
}

.languages {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 2ch;
  gap: 2ch;
  margin-bottom: 2ch;
}

.languages .box {
  flex: 1 1 30rem;
}

.projects {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 2ch;
}

.projects::after {
  position: absolute;
  content: "";
  bottom: -2ch;
  height: 2ch;
  width: 1px;
}

section {
  position: absolute;
  padding: 0 2em;
  box-sizing: border-box;
  font-family: 'Playfair Display', serif;
  font-size: 1.3em;
  transition: all 0.75s cubic-bezier(.95,.05,.8,.04);
  width: 100vw;
}

.current-section {
  top: 10ch;
  right: 0;
  transition: all 0.75s cubic-bezier(.95,.05,.8,.04) 0.8s !important;
}

.outscreen-right {
  top: 10ch;
  right: -100vw;
}

.outscreen-left {
  top: 10ch;
  right: 100vw;
}

.outscreen-top {
  top: -100vh !important;
}

.outscreen-bottom {
  top: 100vh;
}


#nav h1 {
  display: inline-block;
  margin-right: 2em;
}

#links {
  display: inline-block;
}

#nav a {
  font-size: 1.25em;
  font-weight: bold;
}

a {
  color: inherit;
  position: relative;
}

a:focus,
a:hover {
  text-decoration: none;
}

#nav .current-link::before {
  content: "";
  display: block;
  width: 100%;
  height: .2ch;
  left: 0;
  bottom: 0.01em;
  position: absolute;
  background: linear-gradient(to right, red, orange , yellow, green, cyan, blue);
  transform: scale(1);
  text-decoration: none;
}

a::before {
  content: "";
  display: block;
  width: 100%;
  height: .2ch;
  left: 0;
  bottom: 0.01em;
  position: absolute;
  background: linear-gradient(to right, red, orange , yellow, green, cyan, blue);

  transform-origin: left;
  transform: scale(0);
  transition: 0.25s linear;
}

a:focus:before,
a:hover:before {
  transform: scale(1);
}


@media only screen and (min-width:768px) {
  body {
    --starCount: 100;
  }
  #typewriter {
    font-size: 4em;
  }
  #enter {
    font-size: 2em;
  }
  .name-logo {
    display: none;
  }
  .name-full {
    display: inline-block;
    font-weight: bold;
    font-size: 1.5em;
    margin-right: 2em;
  }
  .row {
    flex-wrap: nowrap;
    column-gap: 2ch;
  }
  .row > * {
    flex-basis: 33%;
  }
  #nav {
    margin: 3em;
  }
  #nav a {
    margin-right: 1em;
  }
  section {
    padding: 0 3em;
  }
}
